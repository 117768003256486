<template>
  <Layout>
    <div class="row">
      <div class="col-md-7 col-lg-6">
        <b-form-group class="" label="Title" style="width: 95%">
          <b-form-input
            v-model="data.title"
            type="text"
            name="title"
            label="Title"
            validation="required"
          />
        </b-form-group>
        <b-form-group class="" label="Title(AR)" style="width: 95%">
          <b-form-input
            v-model="data.title_ar"
            type="text"
            name="title_ar"
            label="Title(AR)"
          />
        </b-form-group>
        <b-form-group label="Text">
          <!-- <b-form-input
        v-model="data.text"
        type="text"
        name="text"
        label="Text"
        validation="required" 
      /> -->
          <tiptap
            v-if="data.text"
            :text="data.text"
            :media="data.media"
            @onEditerLoads="updateEditor"
          />
        </b-form-group>

        <b-form-group label="Text(AR)">
          <!-- <b-form-input
        v-model="data.text"
        type="text"
        name="text"
        label="Text"
        validation="required" 
      /> -->
          <tiptap
            :text="arabicText"
            :media="data.media"
            @onEditerLoads="updateEditorAr"
          />
        </b-form-group>
      </div>
      <div class="col-md-5 col-lg-6">
        <h5 class="">Featured Image</h5>
        <div></div>
        <div class="row">
          <div
            class="col-4 mt-0 pt-1"
            v-for="(media, index) in data.media.filter((m) => m.group == 1)"
            :key="index"
          >
            <div
              class="card tribe-details-main-img-1 rounded-lg"
              :style="{
                'background-image': 'url(' + media.media.original + ')',
                backgroundPosition: 'center',
              }"
              v-if="media.type == `image`"
            >
              <!-- <template v-slot:button-content>
                                    <i class="ri-settings-5-line"></i>
                                </template> -->
              <!-- <span class="delete-prg-btn" @click="addImage(media.media.original)"><i class="ri-drag-drop-fill"></i></span> -->

              <!-- <img class="img-resonsive rounded-lg image-fit" :src="media.media.original" srcset=""
                  style="width: 150px; height: 180px; display: block; object-fit: cover;" > -->
            </div>
            <!-- </div> -->
            <!-- <div class="container m-0 p-0 rounded-lg" v-for="item in data.media" :key="item._id" :style="{ 'background-image': 'url(' + media.media.original + ')', 'backgroundPosition': 'center', 'object-fit': 'cover',}" >
            <div v-if="item.type == `image`" >
              <img class="rounded-lg" v-if="item.group == 1" :src=(item.media.original) style="width: 100%" >
            </div>
          </div> -->
            <!-- <img src="https://d2neuv05ura4o9.cloudfront.net/discovery_items/7/original.5d28dec163b5c8.44074845.jpg">  -->
          </div>
          <div class="col-4 mt-0 pt-0">
            <div
              class="card tribe-details-main-img-2 rounded-lg pb-5"
              :style="{ backgroundPosition: 'center', 'object-fit': 'cover' }"
            >
              <span class="empty-prg-btn"
                ><i class="ri-drag-drop-fill" style="color: white"></i
              ></span>
              <div
                class="add-image-btn flex-wrap"
                @click="showFeaturedImage = !showFeaturedImage"
              >
                <i class="ri-add-circle-fill fa-3x flex-wrap"></i>
                Upload Image
              </div>
            </div>
          </div>
          <!-- <div class="col-4 mt-0 pt-1">
              <div class="card tribe-details-main-img pl-5 mt-0 mb-2 pt-2 rounded-lg" :style="{  'backgroundPosition': 'center', 'object-fit': 'cover',}">
                <div class="add-image-btn" @click="showFeaturedImage = !showFeaturedImage">
                  <span class="empty-prg-btn"><i class="ri-drag-drop-fill" style="color: white"></i></span>
                  <i class="ri-add-circle-fill fa-3x"></i>
                  Upload Image
                </div>
               
              </div> -->
          <!-- </div> -->
        </div>
        <!-- <div class="col-md-12 col-lg-12"> -->

        <h5 class="mt-3 mb-0">Images</h5>
        <div class="row">
          <div
            class="col-4 mt-0 pt-1"
            v-for="(media, index) in data.media.filter((m) => m.group != 1)"
            :key="index"
          >
            <div
              class="card tribe-details-main-img-1 rounded-lg p-3 mt-2 mb-2 text-center"
              :style="{
                'background-image': 'url(' + media.media.original + ')',
                backgroundPosition: 'center',
                'object-fit': 'cover',
              }"
              v-if="media.type == `image`"
              style="width: 100%; height: 150px"
            >
              <span
                class="delete-prg-btn right-align"
                style=""
                @click="addImage(media.media.original)"
              >
                <i class="ri-drag-drop-fill"></i>
              </span>
              <!-- <img class="img-resonsive rounded-lg image-fit" :src="media.media.original" srcset=""
                  style="width: 150px; height: 180px; display: block; object-fit: cover;" > -->
            </div>
          </div>
          <div class="col-4 mt-0 pt-0">
            <div
              class="card tribe-details-main-img-2 rounded-lg pb-5 mt-2 mb-3"
              :style="{
                backgroundPosition: 'center',
                'object-fit': 'cover',
                height: '156px',
              }"
            >
              <span class="empty-prg-btn"
                ><i class="ri-drag-drop-fill" style="color: white"></i
              ></span>
              <div class="add-image-btn" @click="modalShow = !modalShow">
                <i class="ri-add-circle-fill fa-3x"></i>
                Upload Image
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4 pl-0">
          <div class="left-align mb-2">
            <b-button
              variant="primary"
              name="image"
              type="submit"
              class="m-auto"
              style="width: 100%"
              @click="updateData"
              >Save</b-button
            >
          </div>
          <!-- </div> -->
          <!-- <div class="row">
                  
                    <div class="col-3" v-for="media, index in  data.media" :key="index">
                      <div class="card m-1 p-4">
                        <img :src="media.media.large" :alt="media.meta.description" srcset="">
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="card m-1 p-4">
                        <FormulateInput type="image" name="images" label="Add Images"
                          validation="mime:image/jpeg,image/png,image/gif" :uploader="uploadCoverImage" />
                      </div>
                    </div>
                  </div> -->
          <!-- <div class="row">
    <div class="col-4" v-for="item in data.media" :key="item._id">
      
   <div v-for="item in data.media" :key="item._id"> 
          <div class="container m-1 p-1" v-if="item.type == `image`" style="width: 150px;">
            <span class="delete-prg-btn" @click="deleteProgram(data)"><i
          class="ri-drag-drop-fill"></i></span>
          <img class="img-resonsive rounded-lg" v-if="item.group != 1" :src=(item.media.original) style="width: 150px; height: 180px; display: block; object-fit: cover">
        </div>
        
        </div>
       
        <div class="container m-1 p-1 rounded-lg" style="background-color: white; width: 150px; height: 180px;">
         <div class="add-image-btn" @click="modalShow = !modalShow">
          <i class="ri-add-circle-fill fa-3x"></i> 
          Upload Image
         </div>

       
    
        
      
       
      </div>
    </div> -->
        </div>
      </div>
    </div>
    <b-modal
      id="upload-image-modal"
      v-model="modalShow"
      hide-footer
      title="Upload Image"
      size="md"
    >
      <FormulateForm>
        <FormulateInput
          class=""
          type="image"
          label=""
          help="Select a png, jpg or gif to upload."
          :uploader="uploadImage"
          validation="required"
        />

        <FormulateInput
          class="float-right"
          type="submit"
          :label="performAction ? 'Uploading...' : 'Upload'"
        />
      </FormulateForm>
    </b-modal>
    <b-modal
      id="upload-featured-image-modal"
      v-model="showFeaturedImage"
      hide-footer
      title="Upload Featured Image"
      size="md"
    >
      <FormulateForm>
        <FormulateInput
          class=""
          type="image"
          label=""
          help="Select a png, jpg or gif to upload."
          :uploader="uploadFeaturedImage"
          validation="required"
        />

        <FormulateInput
          class="float-right"
          type="submit"
          :label="performAction ? 'Uploading...' : 'Upload'"
        />
      </FormulateForm>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/vertical.vue";
import Tiptap from "@/components/text_editor.vue";
import { tribe } from "@/config/api/tribes.js";

export default {
  name: "App",
  props: {
    // featuredImages: {
    //   type: String
    // }
  },

  page: {},

  data() {
    return {
      tiptapEditor: undefined,
      tiptapEditorAr: undefined,

      modalShow: false,
      messageForChild: "I am message from parent component",
      isLoading: false,
      loading: false,
      performAction: false,
      showFeaturedImage: false,
      currentPage: 1,
      data: [],
      title: "sdf",
      title_ar: "sdf",
      text: null,
      text_ar: null,
      featuredImage: null,
      group: [],
      formValues: {
        title: null,
      },
      // featuredImage: null,
    };
  },
  components: {
    Tiptap,
    Layout,
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    last() {
      return Object.keys(this.data.media).length - 1;
    },
    arabicText() {
      return this.data.text_ar || ""; // Return empty string if data.text_ar is falsy
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.loadData();
      // if(this.val){
      // this.formValues = {
      //   ...val,
      // }
      // }
    },

    if(data) {
      this.text = data.text;
      console.log("currentAward", this.data);
    },

    // if(val){
    //   this.formValues = {
    //     title: this.data.title
    //   }
    // }
  },
  methods: {
    addImage(url) {
      if (this.tiptapEditor && this.tiptapEditorAr) {
        // Add image to the first editor
        this.tiptapEditor.chain().focus().setImage({ src: url }).run();

        // Add image to the Arabic editor
        this.tiptapEditorAr.chain().focus().setImage({ src: url }).run();
      }
    },

    updateEditor(editor) {
      console.log(editor, "ENGLISH");
      this.tiptapEditor = editor;
    },
    updateEditorAr(editor) {
      console.log(editor, "ARABIC");
      this.tiptapEditorAr = editor;
    },
    async loadData() {
      console.log("currentAward", this.data);

      const api = {
        url: `/v1/admin/discover/${this.id}`,
        method: "GET",
        data: null,
        id: null,
        params: null,
      };

      this.isLoading = true;
      this.data = [];
      this.text = this.data.text;
      console.log("currentAward", this.data.text);
      await this.generateAPI(api)
        .then((res) => {
          this.data = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateData() {
      // console.log("media", this.data.media);
      const api = {
        url: "/v1/admin/discover",
        method: "POST",
        data: {
          _id: this.data._id,
          title: this.data.title,
          title_ar: this.data.title_ar,

          type: this.data.type,
          text: this.tiptapEditor.getHTML(),
          text_ar: this.tiptapEditorAr.getHTML(),

          media: this.data.media,
          featured_image: this.featuredImage,
        },
      };
      console.log(api.data);
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          // this.data = res.data.ewDiscover;
          console.log("res", res.data.newDiscover);

          console.log("data", this.data);
          this.$bvToast.toast("Data saved successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          // this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast("Data is not saved!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })

        .finally(() => {
          this.loading = false;
        });

      // const api = discover.updateDiscoverForAdmin;
      //   data: {
      //     id: this._id,

      //   }
      // loading = true;
      // this.generateAPI(api).then(() => {
      //   this.loadD
      // })
    },
    async uploadImage(img, progress) {
      console.log(img);
      console.log(progress);
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      //form.append("name", this.values.name);
      const api = tribe.uploadMultimedia;
      api.data = form;
      this.performAction = true;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        console.log(res.data);
        this.performAction = false;
        this.$bvModal.hide("upload-image-modal");
        return res.data;
      });

      // const programAPI = program.addProgramMedia;
      console.log("res", result.data);
      //  this.data.media = {}
      //  console.log(this.data.media);
      let media = {
        createdAt: "",
        discover: "",
        group: "",
        order: "",
        updatedAt: "",
        type: "image",
        media: {
          large: result.data,
          medium: result.data,
          small: result.data,
          original: result.data,
        },
      };
      console.log(this.data.media);
      this.data.media.push(media);
      // console.log(this.data.media);
      // this.performAction = false;
      return await result;
    },
    async uploadFeaturedImage(img, progress) {
      console.log(img);
      console.log(progress);
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      //form.append("name", this.values.name);
      const api = tribe.uploadMultimedia;
      api.data = form;
      this.performAction = true;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        console.log(res.data);
        this.performAction = false;
        this.$bvModal.hide("upload-featured-image-modal");
        return res.data;
      });

      // const programAPI = program.addProgramMedia;
      console.log("res", result.data);

      //  this.data.media = {}
      //  console.log(this.data.media);
      this.featuredImage = result.data;
      console.log("featured", this.featuredImage);

      console.log();
      this.data.media.map(
        (element) => {
          if (element.group == 1) {
            let m = {
              large: this.featuredImage,
              medium: this.featuredImage,
              small: this.featuredImage,
              original: this.featuredImage,
            };

            element.media = m;
            // element.media = this.element.media.replace(element.media, m);
            // console.log("m", this.m)
            console.log("m", m);
            console.log("element", element);

            //   let m1 = element.media;
            // this.m1.replace(m1, m)
            // this.element.media.replace(element.media, m)
            ///this.element.media.replace(this.element.media, m)
            //console.log("new", element.media)
          }
          //this.element.media.push(m);
        }
        // this.data.media.replace(element.media)
      );

      // this.data.media.map((media) => {
      // this.data.media.push(contact.id);
      //});
      // console.log("media", this.data.media);
      // this.forLoop();
      // console.log("group", this.forLoop());
      // for(let i = 0; i < this.data.media; i++) {
      //   console.log("media");
      //   let index = this.data.media;
      //   console.log("index",index[i].group )

      // }
      // if(index == 1 && this.data.media.type == "image"){
      //   console.log("media", this.data.media)
      // }

      // }

      return await result;
    },
    // addToList() {
    //   this.data.push({'media': [], 'text': 'Item 4' });
    // },
  },
};
</script>

<style lang="scss">
/* Basic editor styles */

.tribe-details-main-img-2 {
  height: 168px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tribe-details-main-img-1 {
  height: 168px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.delete-prg-btn {
  background-color: #fff;
  height: 22px;
  width: 22px;
  display: block;
  text-align: center;
  border-radius: 50%;
  // margin-top: 10px;
  //margin-top: 50px;
  // margin-left: 1px;
  // margin-bottom: 10px;
  top: -10px;
  left: -10px;
  //left: 100px;
  position: relative;
}

.delete-prg-btn:hover {
  box-shadow: 0px 0px 5px 0px #e83e8c;
  -webkit-box-shadow: 0px 0px 5px 0px #e83e8c;
  -moz-box-shadow: 0px 0px 5px 0px #e83e8c;
  cursor: pointer;
  position: relative;
}

v-tooltip:hover {
  position: relative;
}

.empty-prg-btn {
  background-color: #fff;
  height: 22px;
  width: 22px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -1px;
  // margin-top: 2px;
  // margin-left: 4px;
  // top: 28px;
  // left: 120px;
  position: relative;
  //display: flex;
}

.empty-prg-btn {
  box-shadow: 0px 0px 5px 0px #fff;
  -webkit-box-shadow: 0px 0px 5px 0px #fff;
  -moz-box-shadow: 0px 0px 5px 0px #fff;
  cursor: pointer;
}

.add-image-btn {
  background-color: #fff;
  height: 60px;
  width: 60px;
  display: block;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 0px;
  margin-left: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  position: relative;
}

.add-image-btn:hover {
  box-shadow: 0px 0px 5px 0px #e83e8c;
  -webkit-box-shadow: 0px 0px 5px 0px #e83e8c;
  -moz-box-shadow: 0px 0px 5px 0px #e83e8c;
  cursor: pointer;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>