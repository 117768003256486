var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col-lg-6"},[_c('b-form-group',{staticStyle:{"width":"95%"},attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"type":"text","name":"title","label":"Title","validation":"required"},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1),_c('b-form-group',{staticStyle:{"width":"95%"},attrs:{"label":"Title(AR)"}},[_c('b-form-input',{attrs:{"type":"text","name":"title_ar","label":"Title(AR)"},model:{value:(_vm.data.title_ar),callback:function ($$v) {_vm.$set(_vm.data, "title_ar", $$v)},expression:"data.title_ar"}})],1),_c('b-form-group',{attrs:{"label":"Text"}},[(_vm.data.text)?_c('tiptap',{attrs:{"text":_vm.data.text,"media":_vm.data.media},on:{"onEditerLoads":_vm.updateEditor}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Text(AR)"}},[_c('tiptap',{attrs:{"text":_vm.arabicText,"media":_vm.data.media},on:{"onEditerLoads":_vm.updateEditorAr}})],1)],1),_c('div',{staticClass:"col-md-5 col-lg-6"},[_c('h5',{},[_vm._v("Featured Image")]),_c('div'),_c('div',{staticClass:"row"},[_vm._l((_vm.data.media.filter(function (m) { return m.group == 1; })),function(media,index){return _c('div',{key:index,staticClass:"col-4 mt-0 pt-1"},[(media.type == "image")?_c('div',{staticClass:"card tribe-details-main-img-1 rounded-lg",style:({
              'background-image': 'url(' + media.media.original + ')',
              backgroundPosition: 'center',
            })}):_vm._e()])}),_c('div',{staticClass:"col-4 mt-0 pt-0"},[_c('div',{staticClass:"card tribe-details-main-img-2 rounded-lg pb-5",style:({ backgroundPosition: 'center', 'object-fit': 'cover' })},[_c('span',{staticClass:"empty-prg-btn"},[_c('i',{staticClass:"ri-drag-drop-fill",staticStyle:{"color":"white"}})]),_c('div',{staticClass:"add-image-btn flex-wrap",on:{"click":function($event){_vm.showFeaturedImage = !_vm.showFeaturedImage}}},[_c('i',{staticClass:"ri-add-circle-fill fa-3x flex-wrap"}),_vm._v(" Upload Image ")])])])],2),_c('h5',{staticClass:"mt-3 mb-0"},[_vm._v("Images")]),_c('div',{staticClass:"row"},[_vm._l((_vm.data.media.filter(function (m) { return m.group != 1; })),function(media,index){return _c('div',{key:index,staticClass:"col-4 mt-0 pt-1"},[(media.type == "image")?_c('div',{staticClass:"card tribe-details-main-img-1 rounded-lg p-3 mt-2 mb-2 text-center",staticStyle:{"width":"100%","height":"150px"},style:({
              'background-image': 'url(' + media.media.original + ')',
              backgroundPosition: 'center',
              'object-fit': 'cover',
            })},[_c('span',{staticClass:"delete-prg-btn right-align",on:{"click":function($event){return _vm.addImage(media.media.original)}}},[_c('i',{staticClass:"ri-drag-drop-fill"})])]):_vm._e()])}),_c('div',{staticClass:"col-4 mt-0 pt-0"},[_c('div',{staticClass:"card tribe-details-main-img-2 rounded-lg pb-5 mt-2 mb-3",style:({
              backgroundPosition: 'center',
              'object-fit': 'cover',
              height: '156px',
            })},[_c('span',{staticClass:"empty-prg-btn"},[_c('i',{staticClass:"ri-drag-drop-fill",staticStyle:{"color":"white"}})]),_c('div',{staticClass:"add-image-btn",on:{"click":function($event){_vm.modalShow = !_vm.modalShow}}},[_c('i',{staticClass:"ri-add-circle-fill fa-3x"}),_vm._v(" Upload Image ")])])])],2),_c('div',{staticClass:"col-md-3 mt-4 pl-0"},[_c('div',{staticClass:"left-align mb-2"},[_c('b-button',{staticClass:"m-auto",staticStyle:{"width":"100%"},attrs:{"variant":"primary","name":"image","type":"submit"},on:{"click":_vm.updateData}},[_vm._v("Save")])],1)])])]),_c('b-modal',{attrs:{"id":"upload-image-modal","hide-footer":"","title":"Upload Image","size":"md"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('FormulateForm',[_c('FormulateInput',{attrs:{"type":"image","label":"","help":"Select a png, jpg or gif to upload.","uploader":_vm.uploadImage,"validation":"required"}}),_c('FormulateInput',{staticClass:"float-right",attrs:{"type":"submit","label":_vm.performAction ? 'Uploading...' : 'Upload'}})],1)],1),_c('b-modal',{attrs:{"id":"upload-featured-image-modal","hide-footer":"","title":"Upload Featured Image","size":"md"},model:{value:(_vm.showFeaturedImage),callback:function ($$v) {_vm.showFeaturedImage=$$v},expression:"showFeaturedImage"}},[_c('FormulateForm',[_c('FormulateInput',{attrs:{"type":"image","label":"","help":"Select a png, jpg or gif to upload.","uploader":_vm.uploadFeaturedImage,"validation":"required"}}),_c('FormulateInput',{staticClass:"float-right",attrs:{"type":"submit","label":_vm.performAction ? 'Uploading...' : 'Upload'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }