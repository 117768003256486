<template>
  <div class="col-12">
    <div v-if="editor" class="container border border-dark rounded-lg"
      style="height: 100%; background-color: #fff; margin-left: -12px">
      <button class="bt btn-sm ri-bold" @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }">
      </button>
      <button class="bt btn-sm ri-italic" @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }">
      </button>
      <button class="bt btn-sm ri-strikethrough" @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }">
      </button>
      <button class="bt btn-sm ri-code-line" @click="editor.chain().focus().toggleCode().run()"
        :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
      </button>
      <button class="bt btn-sm ri-mark-pen-line" @click="editor.chain().focus().toggleHighlight().run()">
      </button>
      <button class="bt btn-sm ri-paragraph" @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }">
      </button>
      <v-divider :thickness="6" class="hrn">
      </v-divider>
      <button class="bt btn-sm ri-h-1" style="margin-bottom: 0px;"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </button>
      <button class="bt btn-sm ri-h-2" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </button>
      <button class="bt btn-sm ri-list-unordered" @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }">
      </button>
      <button class="bt btn-sm ri-list-ordered" @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }">
      </button>
      <button class="bt btn-sm ri-code-box-line" @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }">
      </button>
      <v-divider class="hrn">
      </v-divider>
      <button class="bt btn-sm ri-double-quotes-l" @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }">
      </button>
      <button class="bt btn-sm ri-separator" @click="editor.chain().focus().setHorizontalRule().run()">
      </button>
      <v-divider class="hrn">
      </v-divider>
      <button class="bt btn-sm ri-text-wrap" @click="editor.chain().focus().setHardBreak().run()">
      </button>
      <button class="bt btn-sm ri-arrow-go-back-line" @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()">
      </button>
      <button class="bt btn-sm ri-arrow-go-forward-line" @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()">
      </button>
      <!-- <button @click="addImage">
      setImage
    </button> -->
      <editor-content :editor="editor" />
    </div>
  </div>
</template>
  
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
// import Placeholder from '@tiptap/extension-placeholder'
import Bold from '@tiptap/extension-bold'
import Heading from '@tiptap/extension-heading'
import showdown from 'showdown'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'

export default {
  components: {
    EditorContent,
  },
  name: "tiptap",
  props: {
    text: {
      type: Object,
    },
    media: {
      type: Object,
    },

  },

  data() {
    return {
      editor: null,
      content: this.text,
      value: null,
      group: [],
    }
  },

  watch: {

    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.makeHTML(),
      extensions: [
        StarterKit,
        Bold,
        Image,
        Dropcursor,

        Heading.configure({
          levels: [1, 2, 3],
        }),
        Image.configure({
          inline: true,
          allowBase64: true,
        }),

      ],
    })
    this.currentEditor();
  },

  computed: {

  },

  methods: {
    currentEditor() {
      this.$emit("onEditerLoads", this.editor);
    },
    setLinkUrl(url) {
      if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        url = `https://${url}`
      }

      this.editor.commands.link({ href: url })
      this.$modal.hide('link')
    },

    replace(markdown, media) {

      const matches = markdown.match(/\(image\/\S+\)/g)

      if (!matches || matches.length === 0) {

        return markdown
      }

      for (let i = 0; i < matches.length; i++) {

        let index = this.findImageIndex(media, matches[i])
        if (index !== -1) {

          markdown = markdown.replace(matches[i], `(${media[index].media.original})`)

        }
      }

      return markdown
    },

    findImageIndex(media, id) {

      return media.findIndex(m => `(image/${m._id})` == id)
    },
    convert(text) {
      const converter = new showdown.Converter({
        openLinksInNewWindow: true,
        encodeEmails: true
      })
      return converter.makeHtml(text)
    },
    makeHTML() {
      console.log("text", this.media)
      //   for(let i = 0; i < this.media.length; i++){
      //     let index = this.media;
      //   //  console.log(index[i].group)
      //     // console.log(this.media[i].group)
      //   this.group = index[i].group
      //   console.log(this.group)
      //   if(this.group == 1) {
      //     this.featured = index[i].media.medium;
      //     console.log(this.featured)
      //   }
      //   }
      //    console.log("group:", this.group)
      //  return group


      //  console.log(this.media[0].group)
      return this.convert(this.replace(this.text, this.media))


    },
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
  
<style lang="scss">
.bt {
  border: 0px solid #646c6f;
  align-items: center;
  max-width: 124px;
  padding: 4px;
  font-size: 15px;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  margin-top: 8px;
}

.bt:hover {
  background-color: #000;
  color: white;
}

.hrn {
  border: 1px solid;
  color: rgb(190, 190, 190);
  max-width: initial !important;
  max-height: initial !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
  margin: 5px;
  margin-bottom: -1px !important;

}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  >*+* {
    margin-top: 0.75em;
    background: #fff;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }





}
</style>